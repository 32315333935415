/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Footer from "./footer"
import "../styles"
import { useEffect } from "react"
import Header from "./header"

const Layout = ({ children }) => {
  const [onDarkMode, setOnDarkMode] = useState(true)
  useEffect(() => {
    // if (
    //   window.matchMedia &&
    //   window.matchMedia("(prefers-color-scheme: dark)").matches
    // ) {
    //   setOnDarkMode(true)
    // }
    // window
    //   .matchMedia("(prefers-color-scheme: dark)")
    //   .addEventListener("change", event => {
    //     if (event.matches) {
    //       //dark mode
    //       setOnDarkMode(true)
    //     } else {
    //       //light mode
    //       setOnDarkMode(false)
    //     }
    //   })
  }, [])
  const baseClassName = `${onDarkMode ? "dark-mode" : "light-mode"}`
  // const baseClassName = "dark-mode"

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <div className={baseClassName}>
          <div className="base__container">
            {/* <Header darkMode={onDarkMode} /> */}
            <main>
              <div>{children}</div>
            </main>
            {/* <Footer siteTitle={data.site.siteMetadata.title} /> */}
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
