import React from "react"

function HeaderNavLinks() {
  return (
    <div className="nav__links--container">
      {[
        { t: "About", l: "#about" },
        { t: "Maintainers", l: "#maintainers" },
        { t: "Projects", l: "#projects" },
      ].map((o, i) => {
        return (
          <div className="link__container">
            <a href={o.l}>{o.t}</a>
          </div>
        )
      })}
    </div>
  )
}

export default HeaderNavLinks
