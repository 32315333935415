import React from "react"
import PropTypes from "prop-types"
import Logo from "./logo"
import HeaderNavLinks from "./headerNavLinks"

const Header = ({ siteTitle, darkMode }) => (
  <header className="nav__links">
    <div className="container">
      <Logo dark={darkMode} />
      <HeaderNavLinks />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
